.user-form-header {
  padding-top: 3px;
  height: 33px;
  background-color: #222324;
  color: #39aa24;
  font-weight: 500;
}

#rwrd-availabl {
  color: #656e76;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  margin-bottom: 5px;
}

.data-flow-form {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.data-flow-form div {
  display: flex;
  flex-direction: column;
}

.data-flow-form div label {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
  margin-left: 1px;
}

.data-flow-form div input,
.data-flow-form div textarea {
  font-size: 16px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: all 0.3s ease;
  color: #000;
}

.data-flow-form div input:focus,
.data-flow-form div textarea:focus {
  border-color: #0080ff;
  box-shadow: 0 0 5px #0080ff;
}

.data-flow-form div select,
.data-flow-form div textarea {
  font-size: 16px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: all 0.3s ease;
}

.data-flow-form div select:focus,
.data-flow-form div textarea:focus {
  border-color: #0080ff;
  box-shadow: 0 0 5px #0080ff;
}

.data-flow-form div p {
  color: red;
  font-size: 14px;
  margin-top: 2px;
}

#user-form-btn-sbmit {
  font-size: 18px;
  font-weight: 600;
  padding: 10px 20px;
  background: rgb(42, 184, 89);
  color: #fff;
  border: none;
  border-radius: 35px;
  cursor: pointer;
  transition: all 0.3s ease;
}

#user-form-btn-sbmit:hover {
  background: rgb(23, 150, 65);
}

#user-form-btn-sbmit:disabled {
  background: rgb(1, 18, 7);
  opacity: 50%;
}


#DOB-form {
  display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	align-content: flex-start;
}

.DOB-item {
  width: 33%;
}

#Gender-form {
  display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	align-content: flex-start;
}

.Gender-item {
  width: 33%;
}

#province-select option {
  height: 10px;
}