.toprewards-body-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: center;
  width: 100%;
}


.topreward-body-item {
  width: 50%;
}

#body-photo-toprewards{
  /* background-image: url('../../assets//backgrAI1-dark.png'); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* .toprewards-title-text {
  font-family: 'Poppins', sans-serif;
  font-size: xx-large;
  font-weight: 600;
  color: rgb(22, 41, 79);
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 10px;
} */
.toprewards-headline-text {
  /* font-family: 'Poppins', sans-serif; */
  font-family:Arial, Helvetica, sans-serif;
  font-size: x-large;
  font-weight: 600;
  color: rgb(44, 78, 146);
  padding-left: 3%;
  padding-right: 3%;
  margin-top: 5px;

}

#promo-image {
  max-width: 200px;
  max-height: 200px;
}

.disclaimer {
  margin-top: 10px;
  padding-left: 40px;
  padding-right: 40px;
  font-size: x-small;
}
.disclaimer2 {
  padding-left: 50px;
  padding-right: 50px;
  font-size: x-small;
}


@media only screen and (max-width: 800px) {
  .toprewards-body-container {
    display: flex;
    flex-direction: column;
  }

  .topreward-body-item {
    width: 100%;
    /* background-image: url('../../assets//backgrAI1-dark2.webp'); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .toprewards-title-text {
    color: rgb(36, 33, 33);
    text-shadow: 0.5px 0.5px grey;
  }
  .toprewards-headline-text {
    color: rgb(29, 30, 31);
  }

  .disclaimer {
    color: white;
    margin-top: 10px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: x-small;
  }
  .disclaimer2 {
    color: white;

    padding-left: 30px;
    padding-right: 30px;
    font-size: xx-small
  }


}


@media only screen and (max-width: 410px) {

  .topreward-body-item {
    width: 100%;
    /* background-image: url('../../assets/backgrAI1-dark3.webp'); */

  }

}

@media only screen and (max-width: 800px) {
  #promo-image {
    max-width: 50%;
  }

  .toprewards-title-text {
    font-size: large;
    font-weight: 600;
    color: rgb(18, 18, 19);
    text-shadow: 0.5px 0.5px rgb(29, 27, 27);
  }
  .toprewards-headline-text {

    font-size: xx-large;
    font-weight: 600;
    color: rgb(33, 31, 31);
    text-shadow: 0.5px 0.5px rgb(3, 9, 31);
  }
  
}

@media only screen and (max-width: 430px) {
  #promo-image {
    max-width: 55%;
  }

  .toprewards-title-text {
    font-size: large;
    font-weight: 600;
    color: rgb(18, 18, 19);
    text-shadow: 0.5px 0.5px rgb(29, 27, 27);
  }
  .toprewards-headline-text {

    font-size: xx-large;
    font-weight: 600;
    color: rgb(24, 23, 23);
    text-shadow: 0.5px 0.5px rgb(3, 9, 31);
  }
}




