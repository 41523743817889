.questions-div{

}
#question-caption{
  color: rgb(27, 45, 86);
  font-weight: 500;

}

.questions-form-container {
  border: 2px solid rgb(29, 49, 94);
  min-width: 9%;
  min-height: 215px;
  margin-left: 5%;
  margin-right: 5%;
  padding: 10px;
  border-radius: 10px;
  background-color: rgb(252, 252, 252);

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
}
.q-head {
  margin-bottom: 8px;
}
.question-answer-btn {
  background: rgb(46, 207, 100);
  min-width: 80px;
  font-weight: 720;
  font-size: 17px;
  border-radius: 25px;
  border: none;
  padding: 8px;
  color: white;
  margin-right: 10px;
  margin-bottom: 8px;
}