.samples-body-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: center;
  width: 100%;
}

.sample-body-item {
  width: 50%;
}

#body-photo-samples {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.headline-text {
  font-family: 'Poppins', sans-serif;
  font-size: xxx-large;
  font-weight: 600;
  color: rgb(44, 78, 146);
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 10px;
}

.title-text {
  font-family: 'Poppins', sans-serif;
  font-size: xxx-large;
  font-weight: 600;
  color: rgb(44, 78, 146);
  padding-left: 3%;
  padding-right: 3%;
}

#promo-image-samples {
  max-width: 300px;
  max-height: 300px;
}

.disclaimer {
  margin-top: 10px;
  padding-left: 40px;
  padding-right: 40px;
  font-size: x-small;
}
.disclaimer2 {
  padding-left: 50px;
  padding-right: 50px;
  font-size: x-small;
}

@media only screen and (max-width: 800px) {
  .samples-body-container {
    display: flex;
    flex-direction: column;
  }

  .sample-body-item {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .title-text {
    color: rgb(255, 255, 255);
    text-shadow: 0.5px 0.5px grey;
  }
  .headline-text {
    color: rgb(226, 232, 238);
  }

  .disclaimer {
    color: white;
    margin-top: 10px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: x-small;
  }
  .disclaimer2 {
    color: white;

    padding-left: 30px;
    padding-right: 30px;
    font-size: xx-small;
  }
}

@media only screen and (max-width: 440px) {
  .sample-body-item {
    width: 100%;
  }
}

@media only screen and (max-width: 800px) {
  #promo-image-samples {
    max-width: 60%;
  }

  .title-text {
    visibility: hidden;
    font-size: xx-small;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    max-height: 1px;
  }
  .headline-text {
    visibility: hidden;
    font-size: xx-small;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    max-height: 1px;
  }
}

@media only screen and (max-width: 600px) {
  #promo-image-samples {
    max-width: 70%;
  }
}

@media only screen and (max-width: 470px) {
  #promo-image-samples {
    max-width: 75%;
  }
}

@media only screen and (max-width: 430px) {
  #promo-image-samples {
    max-width: 80%;
  }
}
