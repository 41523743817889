.sr-topheader-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  max-height: 50px;
  min-height: 50px;
}

.topheader-left-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.topheader-logo1 {
  height: 33px;
  margin-top: 10px;
  margin-left: 10px;
}

.topheader-right-item {
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.topheader-right-item a {
  color: rgb(68, 68, 68);
  font-size: small;
  font-weight: 500;
}
.topheader-right-item a:link {
  text-decoration: none;
}
.topheader-right-item a:hover {
  color: rgb(36, 36, 36);

}
