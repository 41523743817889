.whole-form {
  padding-bottom: 20px;
}
.email-form-container {
  border: 2px solid rgb(29, 49, 94);
  margin-left: 10%;
  margin-right: 10%;
  min-height: 138px;
  padding: 10px;
  border-radius: 10px;
  background-color: rgb(252, 252, 252);

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
}
.email-form-container-success {
  border: 3px solid rgb(56, 209, 107);
  background-color: rgb(252, 252, 252);
  margin-left: 10%;
  margin-right: 10%;
  padding: 10px;
  border-radius: 10px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
}

@media only screen and (max-width: 800px) {
  .email-form-container {
    margin-left: 8%;
    margin-right: 8%;
  }
  .email-form-container-success {
    margin-left: 8%;
    margin-right: 8%;
  }
}

@media only screen and (max-width: 520px) {
  .email-form-container {
    margin-left: 5%;
    margin-right: 5%;
  }
  .email-form-container-success {
    margin-left: 5%;
    margin-right: 5%;
  }

  #email-input {
    width: 100%;
  }
}

@media only screen and (max-width: 350px) {
  .email-form-container {
    margin-left: 5%;
    margin-right: 5%;
  }
  .email-form-container-success {
    margin-left: 5%;
    margin-right: 5%;
  }
}

.email-form {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  margin-left: 28px;
  width: 90%;
}

.email-label {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: x-large;
  color: rgb(29, 49, 94);
  margin-bottom: 12px;
}
#email-input {
  border-radius: 5px;
  border: 1.5px solid black;
  padding: 3px;
  padding-left: 5px;
  background-color: rgb(246, 246, 246);
  text-align: center;
  min-height: 40px;
  font-weight: 400;
  width: 80%;
}



.success-icon {
  height: 25px;
  width: 25px;
  margin-left: 3px;
  padding-bottom: 0px;
}

.not-success {
  margin-right: 28px;
}

.btn-success1 {
  color: rgb(189, 209, 56);
}

.samples-letsgo-btn {
  background: #fbab42;
  font-weight: 800;
  font-size: 24px;
  border-radius: 5px;
  color: white;
  border: none;
  padding: 5px;
  box-shadow: 1px 1px rgb(35, 34, 34);
}

.rewards-btn-getit {
  background: rgb(46, 207, 100);
  font-weight: 720;
  font-size: 22px;
  border-radius: 10px;
  border: none;
  padding: 10px;
  color: white;
  box-shadow: 1px 1px rgb(35, 34, 34);
}

.toprewards-btn-getit {
  background: rgb(23, 166, 32);
  font-weight: 720;
  font-size: 22px;
  border-radius: 10px;
  border: none;
  padding: 10px;
  color: white;
  box-shadow: 1px 1px rgb(35, 34, 34);
}
