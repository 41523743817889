

.main-container {
  display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
}

#loading-text {
  font-family: 'Poppins', sans-serif;
  font-size: xx-large;
  font-weight: 600;
}
.loader-div {
  padding: 10%;
  margin-bottom: 150px;
}