.samples-header-container {
  background: linear-gradient(to right, #4caf50, #ffa500);
  color: #fff;
  background-image: '../../assets/deliveryguy.png';

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: auto;
}

.header-checkbox {
  visibility: hidden;
  height: 30px;
  width: 30px;
}
#brand-text {
  visibility: hidden;
  width: 0px;
}

#text-1 {
  visibility: hidden;
}

#text-2 {
  visibility: hidden;
  line-height: 30px;
}

.header-logo2 {
  height: 33px;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 5px;
}

@media only screen and (max-width: 800px) {
  .samples-header-container {
    background: linear-gradient(to right, #4caf50, #ffa500);
    color: #fff;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
  }

  .header-checkbox {
    visibility: visible;
  }

  .header-logo2 {
    visibility: hidden;
    width: 0px;
  }
  #brand-text {
    visibility: visible;
    font-size: 15px;
    font-family: 'Apple Chancery', 'cursive';
  }

  #text-1 {
    visibility: visible;
    color: rgb(255, 255, 255);
    font-family: 'Poppins', sans-serif;
    font-size: 26px;
    font-weight: 600;
  }

  #text-2 {
    visibility: visible;
    color: rgb(255, 255, 255);
    font-family: 'Poppins', sans-serif;
    font-size: 29px;
    font-weight: 600;
  }
}

.header-right-item1 {
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.header-right-item1 a {
  color: rgb(68, 68, 68);
  font-size: small;
  font-weight: 500;
}
.header-right-item1 a:link {
  text-decoration: none;
}
.header-right-item1 a:hover {
  color: rgb(36, 36, 36);
}

@media only screen and (max-width: 800px) {
  .header-right-item1 {
    visibility: hidden;
  }
}
